import React, { useEffect, useState } from "react";
import SubMenu from "../../menu/menu";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs, EffectFade, Autoplay } from "swiper";
import axios from 'axios';
import image from "../image";
import './jakcMall.scss';
import { jakcMallContent } from "../../../../copywriting";
import null2 from '../images/2.jpg';

let url = 'https://www.jingankerrycentre.com';
if (window.location.origin.includes('www.jingankerrycentre.com')) {
  url = 'https://www.jingankerrycentre.com';
}
if (window.location.origin.includes('qa-jakc-www.kerryplus.com')) {
  url = 'https://qa-jakc-www.kerryplus.com';
}
const JakcMall = ({ currentTab, scrollTop, lang }) => {
    const [height, setHeight] = useState(0);
    const [list, setList] = useState([]);
    useEffect(() => {
        window.addEventListener('resize', getHeight.bind(this)); //监听窗口大小改变
        setTimeout(() => {
            getHeight()
        }, 300)
        return () => window.removeEventListener('resize', getHeight())
    }, []);

    useEffect(() => {
        axios.get(`${url}/jeecg-boot/imgSetting/imgSetting/list?useType=logowall&pageNo=1&pageSize=100`).then(res => {
            // console.log(res.data)
            if (res.data.success) {
                let data = res.data.result ? res.data.result.records : [];
                setList(data);
            }
        }).catch(err => {
            console.log(err)
        })
    }, []);

    const getHeight = () => {
        let dom = document.querySelector(".jakcMall-context")
        if (!dom) return
        let heigth = dom.clientHeight;
        setHeight(heigth)
    }
    const showContext = () => {
        let dom = document.getElementById('jakcMall')
        if (dom && (scrollTop > dom.offsetTop || currentTab === 'jakcMall')) {
            return true
        }
        return false
    }
    return (
        <>
            <div className="jakcMall-container scroll-item" id='jakcMall'>
                <div className={`jakcMall-main ${showContext() ? 'active' : ''}`}>
                    <div className={`jakcMall-context`}>
                        <div className="context">
                            <div className="context-header">
                                <span style={{visibility: "hidden"}}>jing an kerry centre </span>
                                <span className="context-header-connecting-line"
                                    style={{visibility: "hidden"}}
                                ></span>
                                <span style={{visibility: "hidden"}}> centre · core · creativity · connection</span>
                            </div>
                            <div className="describe">
                                <div className="context-left">
                                    <div className="title">
                                        <img src={jakcMallContent.meetOurBrand[lang]} alt="" />
                                    </div>
                                </div>
                                <div className="context-right">
                                    <img src={null2} className="nullimg" />
                                    <div className="text-one">{jakcMallContent.description1[lang]}</div>
                                </div>
                            </div>
                            <div className="brand-list">
                                {/* {list.map(v => {
                                    return <img src={v.imgUrl} alt="" className="brand-item" />
                                })} */}
                                {list.length > 0 && <Swiper
                                    loop={true}
                                    modules={[Autoplay, FreeMode, EffectFade, Thumbs, Navigation]}
                                    autoplay={{ delay: 5000, disableOnInteraction: false }}
                                    pagination
                                    // effect="fade"
                                    navigation={{
                                        nextEl: ".arrow-right",
                                        prevEl: ".arrow-left",
                                    }}
                                    style={{ width: '100%', position: 'relative' }}
                                    observer={true}
                                >
                                    <img src={image.arrow_left} alt="" className="arrow-left" style={{left: 0}}/>
                                    <img src={image.arrow_right} alt="" className="arrow-right" style={{right: 0}}/>
                                    {
                                        list.map((el, i) => {
                                            return <SwiperSlide key={i}>
                                                <img 
                                                    src={el.imgUrl || el}
                                                     alt="" 
                                                    className="swiper-img" 
                                                />
                                            </SwiperSlide>
                                        })
                                    }
                                </Swiper>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default JakcMall;
