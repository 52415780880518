import React, { useEffect, useState } from "react";
import image from "../about/image";
import './contact.scss';
import SubMenu from "../menu/menu";
import Header from "../header";
import Footer from "../footer";
import axios from 'axios';
var sensors = require('sa-sdk-javascript');

let url = 'https://www.jingankerrycentre.com';
if (window.location.origin.includes('www.jingankerrycentre.com')) {
  url = 'https://www.jingankerrycentre.com';
}
if (window.location.origin.includes('qa-jakc-www.kerryplus.com')) {
  url = 'https://qa-jakc-www.kerryplus.com';
}
const Contact = () => {
  const [logoHeight, setLogoHeight] = useState(0);
  const [lang, setLang] = useState('zh');
  const [typeList, setTypeList] = useState([
    {label: '商场租赁', value: '商场租赁'},
    {label: '办公租赁', value: '办公租赁'},
    {label: '基础设施和服务', value: '基础设施和服务'},
    {label: '推广活动、广告及场地租赁', value: '推广活动、广告及场地租赁'},
    {label: '公寓服务', value: '公寓服务'},
    {label: '其他', value: '其他'},
  ]);
  const [type, setType] = useState('商场租赁');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [notes, setNotes] = useState('');
  const allplace = [
    {zh: '洽询类型: ', tzh: '洽詢類型: ', en: 'Enquiry Type: '},
    {zh: '您的称呼: ', tzh: '您的稱呼: ', en: 'Name: '},
    {zh: '您的邮箱: ', tzh: '您的郵箱: ', en: 'E-mail: '},
    {zh: '联系电话: ', tzh: '聯繫電話: ', en: 'Phone Number: '},
    {zh: '留言: ', tzh: '留言: ', en: 'Inquiry details: '},
  ]

  useEffect(() => {
    setTimeout(() => {
      getLogoHeight()
    }, 200)

    if (sessionStorage.getItem('lang')) {
      // console.log(sessionStorage.getItem('lang'))
      setLang(sessionStorage.getItem('lang'));
      getLang(sessionStorage.getItem('lang'));
    }
  }, []);

  const getLogoHeight = () => {
    let dom = document.getElementById('top-logo');
    let height = 0;
    if (dom) {
      height = dom.clientHeight;
    }
    setLogoHeight(height)
  }

  function getLang(lang) {
    // console.log(lang, '--设置')
    setLang(lang);
    if (lang === 'zh') {
      let list = [
        {label: '商场租赁', value: '商场租赁'},
        {label: '办公租赁', value: '办公租赁'},
        {label: '基础设施和服务', value: '基础设施和服务'},
        {label: '推广活动、广告及场地租赁', value: '推广活动、广告及场地租赁'},
        {label: '公寓服务', value: '公寓服务'},
        {label: '其他', value: '其他'},
      ];
      setTypeList(list);
    } else if (lang === 'en') {
      let list = [
        {label: 'Retail Leasing Inquiries', value: '商场租赁'},
        {label: 'Office Leasing Inquiries', value: '办公租赁'},
        {label: 'Facilities and Services', value: '基础设施和服务'},
        {label: 'Marketing, Advertisement and Venue Inquiries', value: '推广活动、广告及场地租赁'},
        {label: 'Appartment Services', value: 'Appartment Services'},
        {label: 'Others', value: '其他'},
      ];
      setTypeList(list);
    } else if (lang === 'tzh') {
      let list = [
        {label: '商場租賃', value: '商场租赁'},
        {label: '辦公租賃', value: '办公租赁'},
        {label: '基礎設施和服務', value: '基础设施和服务'},
        {label: '推廣活動、廣告及場地租賃', value: '推广活动、广告及场地租赁'},
        {label: '公寓服務', value: '公寓服務'},
        {label: '其他', value: '其他'},
      ];
      setTypeList(list);
    }
  }

  function changeType() {
    // console.log(document.getElementById("subject").value);
    let val = document.getElementById("subject").value || '';
    setType(val);
  }

  function changeInput() {
    let name = document.getElementById("name").value || '';
    setName(name);
    let email = document.getElementById("email").value || '';
    setEmail(email);
    let phone = document.getElementById("number").value || '';
    setPhone(phone);
    let notes = document.getElementById("contactForm").value || '';
    setNotes(notes);
  }
  
  function submit() {
    if (!type || !notes) {
      window.alert('请选择类型并输入相应信息！')
      return 
    }

    sensors.track('Submit', {});
    axios.post(`${url}/jeecg-boot/jakcMessage/jakcMessage/add`, {
      msgType: type,
      custName: name,
      email: email,
      phone: phone,
      message: notes,
    }).then( res => {
      // console.log(res.data)
      if (res.data.success) {
        window.alert('提交信息成功')
        window.location.reload();
      }
    }).catch( err => {
      console.log(err)
    })
  }

  return (
    <>
      <div className="contact-container">
        <Header currentTab={'contact'} hiddenMenu={() => { }} subMenuShow={false}
          getLang={getLang}
        />
        <img className="contact-1" src={image.about1} alt="" style={{ height: logoHeight + 'px' }} />
        <div style={{ height: logoHeight + 'px' }}></div>
        <div className="contact-main">
          <div className="contact-context">
            <div className="context">
              <div className="context-header">
                <span style={{visibility: "hidden"}}>jing an kerry centre </span>
                <span className="context-header-connecting-line"
                  style={{visibility: "hidden"}}
                ></span>
                <span style={{visibility: "hidden"}}> centre · core · creativity · connection</span>
              </div>
              <div className="describe">
                {lang === 'zh' && <div className="context-left">
                  <div className="title">
                  <img src={image.ask_us_zh} alt="" />
                    {/* <div className="title-2 label-margin">
                      <div>联系</div>
                      <div>我们</div>
                    </div> */}
                  </div>
                </div>}
                {lang === 'tzh' && <div className="context-left">
                  <div className="title">
                  <img src={image.ask_us_tzh} alt="" />
                    {/* <div className="title-2 label-margin">
                   
                      <div>聯繫</div>
                      <div>我們</div>
                    </div> */}
                  </div>
                </div>}
                {lang === 'en' && <div className="context-left">
                  <div className="title">
                  <img src={image.ask_us_en} alt="" />
                    {/* <div className="title-1">
                      <div>can’t </div>
                      <div>meet?</div>
                    </div>
                    <div className="title-2 label-margin">
                      <div>ask</div>
                      <div>us</div>
                    </div> */}
                  </div>
                </div>}
                <div className="le-ou">
                <div className="context-right">
                  {lang === 'zh' && <div className="title1 blue-color" style={{marginBottom: '5px'}}>请留下您的联系方式和问题，我们会尽快和您取得联络</div>}
                  {lang === 'tzh' && <div className="title1 blue-color" style={{marginBottom: '5px'}}>請留下您的聯繫方式和問題，我們會盡快與您取得聯絡</div>}
                  {lang === 'en' && <div className="title1 blue-color" style={{marginBottom: '5px'}}>Please fill in the form below. We will get back to you ASAP.</div>}
                  <div className="row">
                    <div className="from-two">
                      <select
                        name="subject"
                        id="subject"
                        className="form-select"
                        value={type}
                        onChange={changeType}
                        placeholder={allplace[0][lang]}
                      >
                        {typeList.map( item => {
                          return <option value={item.value}>{item.label}</option>
                        })}
                      </select>
                    </div>
                    <div className="from-two">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder={allplace[1][lang]}
                        value={name}
                        onChange={changeInput}
                      />
                    </div>
                    <div className="from-two">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder={allplace[2][lang]}
                        value={email}
                        onChange={changeInput}
                      />
                    </div>
                    <div className="from-two">
                      <input
                        type="text"
                        name="number"
                        id="number"
                        placeholder={allplace[3][lang]}
                        value={phone}
                        onChange={changeInput}
                      />
                    </div>
                  </div>
                  <div className="from-one">
                    <textarea
                      id="contactForm"
                      value={notes}
                      onChange={changeInput}
                      placeholder={allplace[4][lang]}
                    />
                  </div>
                  <div className="form-btn">
                    <button className="concat-btn title-1" onClick={submit}>
                      {lang === 'en' ? 'submit' : '提交'}
                      <img src={image.yellow_icon} alt="" className="form-arrow-icon"/>
                    </button>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <Footer lang={lang}/>
        </div>
      </div>

      <SubMenu onChange={() => { }} currentTab={'contact'} hiddenMenu={() => { }} subMenuShow={false} 
        activeColor={'#003593'}
        defaultColor={'#000000'}
        lang={lang}
      />
    </>
  );
};

export default Contact;
