import React, { useEffect, useState } from "react";
import axios from "axios";
import image from "../image";
import './address.scss';
import SubMenu from "../../menu/menu";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../../footer";
import { addressContent } from "../../../../copywriting";
import zmap from '../images/zmap.png';
import tmap from '../images/tmap.png';
import emap from '../images/emap.png';

let url = 'https://www.jingankerrycentre.com';
if (window.location.origin.includes('www.jingankerrycentre.com')) {
  url = 'https://www.jingankerrycentre.com';
}
if (window.location.origin.includes('qa-jakc-www.kerryplus.com')) {
  url = 'https://qa-jakc-www.kerryplus.com';
}
const Address = ({ lang }) => {
    let [height, setHeight] = useState(0);
    const [codeImgs, setCodeImgs] = useState([]);
    const [subWay, setSubWay] = useState(null);
    const [park, setPark] = useState(null);

    useEffect(() => {
        getCodeImgs();
        getAddress();
        window.addEventListener('resize', getHeight.bind(this)); //监听窗口大小改变
        setTimeout(() => {
            getHeight()
        }, 100)
        return () => window.removeEventListener('resize', getHeight());
    }, []);

    function getCodeImgs() {
        axios.get(`${url}/jeecg-boot/imgSetting/imgSetting/list?useType=barCode`).then( res => {
          // console.log(res.data)
          if (res.data.success) {
            let data = res.data.result ? res.data.result.records : [];
            setCodeImgs(data);
          }
        }).catch( err => {
          // console.log(err)
        })
    }

    function getAddress() {
        axios.get(`${url}/jeecg-boot/jakcText/jakcText/list`).then( res => {
        // console.log(res.data)
        if (res.data.success) {
            if (res.data.result && res.data.result.records && res.data.result.records.length > 0) {
                res.data.result.records.map( v => {
                    if (v.name == '地铁') {
                        let add = {
                            zh: v.cnContent,
                            tzh: v.twContent,
                            en: v.enContent.split('\n'),
                        }
                        setSubWay(add);
                    }
                    if (v.name == '停车') {
                        let park = {
                            zh: v.cnContent.split('\n'),
                            tzh: v.twContent.split('\n'),
                            en: v.enContent.split('\n'),
                        }
                        // console.log(park)
                        setPark(park);
                    }
                })
            }
        }
        }).catch( err => {
            console.log(err)
        })
    }

    const getHeight = () => {
        let dom = document.querySelector(".address-context");
        if (!dom) return
        let heigth = dom.clientHeight;
        setHeight(heigth)
    }

    return (
        <>
                <div className="address-container scroll-item" id='address'>
                    <div className="address-main">
                        <div className="address-context">
                            <div className="context">
                                <div className="context-header">
                                    <span style={{visibility: "hidden"}}>jing an kerry centre </span>
                                    <span className="context-header-connecting-line"
                                        style={{visibility: "hidden"}}
                                    ></span>
                                    <span style={{visibility: "hidden"}}> centre · core · creativity · connection</span>
                                </div>
                                <div className="describe">
                                    <div className="context-left">
                                        <div className="title">
                                            <img src={addressContent.meetHere[lang]} alt="" />
                                        </div>
                                        <div className="title-6 blue-color">{addressContent.address[lang]}</div>
                                        <div className="text-one blue-color" style={{marginBottom: '5px'}}>{addressContent.addressNo[lang]}</div>
                                        <div className="road-map">
                                            <div className="map">
                                                {lang == 'zh' && <img src={zmap} alt="" />}
                                                {lang == 'tzh' && <img src={tmap} alt="" />}
                                                {lang == 'en' && <img src={emap} alt="" />}
                                            </div>
                                            <div className="road">
                                                <div className="address-detail">
                                                    <div className="title-6 blue-color">{addressContent.openingHours[lang]}</div>
                                                    <div className="time blue-color">
                                                        <img src={image.lock} alt="" />10:00 - 22:00
                                                    </div>
                                                    <div className="get-there">
                                                        <div className="title-6 blue-color">{addressContent.getThere[lang]}</div>
                                                        <div className="subway">
                                                            <img src={image.subway} alt="" className="icon-png" />
                                                            <div className="text-one blue-color">
                                                                <div>{addressContent.subWey[lang]}:</div>
                                                                {lang == 'zh' && <div>{subWay ? subWay[lang] : '2、7、14号线静安寺站6号出口'}</div>}
                                                                {lang == 'tzh' && <div>{subWay ? subWay[lang] : '2、7、14號線靜安寺站6號出口'}</div>}
                                                                {lang == 'en' && <div>
                                                                    <div>{subWay ? subWay[lang][0] : 'Metro Line 2, 7, 14'}</div>
                                                                    <div>{subWay ? subWay[lang][1] : 'Exit 6, Jing An Temple'}</div>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                        <div className="car-park">
                                                            <img src={image.car} alt="" />
                                                            <div className="text-one blue-color">
                                                                <div>
                                                                    <div>{addressContent.carPark[lang]}:</div>
                                                                    {lang == 'zh' && <div>{park ? park[lang][0] : '共约1,500个停车位'}</div>}
                                                                    {lang == 'tzh' && <div>{park ? park[lang][0] : '共約1,500個停車位'}</div>}
                                                                    {lang == 'en' && <div>{park ? park[lang][0] : 'About 1,500 parking spaces'}</div>}
                                                                </div>
                                                                <div className="label-margin">
                                                                    <div>{addressContent.drivingRoute[lang]}:</div>
                                                                    {lang == 'zh' && <div>{park ? park[lang][1] : '驾驶至安义路、铜仁路停车场入口'}</div>}
                                                                    {lang == 'tzh' && <div>{park ? park[lang][1] : '駕駛至安義路、銅仁路停車場入口'}</div>}
                                                                    {lang == 'en' && <div>{park ? park[lang][1] : 'Drive to Anyi Rd. or Tongren Rd.'}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="split-line"></div>
                                                <div className="connect">
                                                    <div className="title-6 blue-color">{addressContent.followUs[lang]}</div>
                                                    <div className="connect-img">
                                                        <img 
                                                            src={codeImgs.length > 0 ? codeImgs[0].imgUrl : image.weixin} 
                                                            alt="" style={{ marginRight: '7px' }} />
                                                        <img 
                                                            src={codeImgs.length > 0 ? codeImgs[1].imgUrl : image.xiaohongshu} 
                                                            alt="" 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer style={{ background: '#fff' }} lang={lang} />
                </div>
        </>

    );
};

export default Address;
